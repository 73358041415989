import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Certification, ColleaguePractitioner, ColleagueUnsubscribedPractitioner, ContinuingFormation, IPicture, JobExperience, PermissionsEnum, Practitioner, Transportation } from '@appines/appines_types';
import { StateStoreService } from 'src/services/state-store/state-store.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { catchError, from, map, Observable, Subject, switchMap, tap, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular/standalone';
import { CustomUploadComponent } from 'src/components/custom-upload/custom-upload.component';

export interface UsersAccess {
  status: 'ACCEPTED' | 'PENDING' | 'REFUSED';
  users: UserAccess[];
}

export interface UserAccess {
  permissions: PermissionsEnum[];
  user: {
    email: string;
    firstName: string;
    lastName: string;
    picture: IPicture;
    _id: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PractitionerService extends StateStoreService<Practitioner> {
  private http = inject(HttpClient);
  private router = inject(Router);
  private modalController = inject(ModalController);

  setPractitionerInState(id: string) {
    if (!this.state()?._id || this.state()?._id !== id) {
      this.getPractitioner(id).subscribe({
        error: (e) => {
          console.log('error: ', e);
          this.router.navigate(['/profile']);
        }
      });
    }
  }

  getPractitioner(id: string) {
    const url = environment.apiUrl + `/practitioners/${id}`;

    return this.http.get<Practitioner>(url).pipe(
      map((data) => {
        this.setState(data);
        return data;
      })
    );
  }

  getPractitioners(page?: number) {
    const url = new URL('/practitioners', environment.apiUrl);
    if (page) url.searchParams.set('page', page.toString());

    return this.http.get<{ data: Practitioner[]; total: number }>(url.toString());
  }

  updatePractitioner(id: string, firstName: string, lastName: string, rpps: string) {
    const url = environment.apiUrl + `/practitioners/${id}/mainInfos`;
    const body = {
      firstName,
      lastName,
      identifier: {
        value: rpps
      }
    };
    return this.http.put<Practitioner>(url, body).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  uploadPractitionerPicture(id: string, data: string): Observable<any> {
    const url = environment.apiUrl + `/practitioners/${id}/upload-picture`;

    const response = new Subject<any>();
    const progress = signal<number>(0);

    const upload$ = from(
      this.modalController.create({
        component: CustomUploadComponent,
        componentProps: { progress },
        cssClass: ['dynamic-height-modal', 'no-scroll-dynamic-height-modal'],
        backdropDismiss: false
      })
    ).pipe(
      switchMap((loadingModal) => {
        loadingModal.present();
        return this.http.put<any>(url, { data }, { reportProgress: true, observe: 'events' }).pipe(
          tap((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  progress.set(Math.round((100 * event.loaded) / event.total));
                }
                break;
              case HttpEventType.Response:
                loadingModal.dismiss();
                response.next(event.body);
                break;
            }
          }),
          catchError((error) => {
            loadingModal.dismiss();
            console.error('An error appear during upload', error);
            return throwError(() => error);
          })
        );
      })
    );

    return upload$.pipe(
      switchMap(() =>
        response.pipe(
          map((res) => {
            this.set('picture', res);
            return res;
          })
        )
      )
    );
  }

  deletePractitionerPicture(id: string) {
    const url = environment.apiUrl + `/practitioners/${id}/delete-picture`;
    return this.http.delete<IPicture>(url).pipe(
      map((res) => {
        this.set('picture', undefined);
        return res;
      })
    );
  }

  addSkillToPractitioner(params: { practitionerId: string; skillId: string }) {
    const url = environment.apiUrl + `/practitioners/${params.practitionerId}/skills`;

    return this.http.post<Practitioner>(url, { skillId: params.skillId }).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  deleteSkillToPractitioner(params: { practitionerId: string; skillId: string }) {
    const url = environment.apiUrl + `/practitioners/${params.practitionerId}/skills/${params.skillId}`;
    return this.http.delete<Practitioner>(url).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  updateTransportationToPractitioner(transportations: Transportation[]) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/transportations`;

    return this.http.put<Practitioner>(url, { transportations }).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  editDescription(id: string, description: string) {
    const url = environment.apiUrl + `/practitioners/${id}/description`;
    return this.http.put<Practitioner>(url, { description }).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  addCertificationPractitioner(certification: Certification) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/certifications`;
    return this.http.post<Practitioner>(url, certification).pipe(
      map((practitionerUpdated) => {
        this.set('certifications', practitionerUpdated.certifications);
        return practitionerUpdated;
      })
    );
  }

  updateCertificationPractitioner(certification: Certification, certificationId: string) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/certifications/${certificationId}`;

    return this.http.put<Practitioner>(url, certification).pipe(
      map((practitionerUpdated) => {
        this.set('certifications', practitionerUpdated.certifications);
        return practitionerUpdated;
      })
    );
  }

  deleteCertificationPractitioner(certificationId: string) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/certifications/${certificationId}`;

    return this.http.delete<Practitioner>(url).pipe(
      map((practitionerUpdated) => {
        this.set('certifications', practitionerUpdated.certifications);
        return practitionerUpdated;
      })
    );
  }

  addFormationPractitioner(formation: ContinuingFormation) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/formations`;
    return this.http.post<Practitioner>(url, formation).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  editFormationPractitioner(formationId: string, formation: ContinuingFormation) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/formations/${formationId}`;
    return this.http.put<Practitioner>(url, formation).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  deleteFormationPractitioner(formationId: string) {
    const url = environment.apiUrl + `/practitioners/${this.state()._id}/formations/${formationId}`;
    return this.http.delete<Practitioner>(url).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  createExperience(practitionerId: string, experience: JobExperience) {
    const url = environment.apiUrl + `/practitioners/${practitionerId}/jobExperiences`;
    return this.http.post<Practitioner>(url, experience).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  updateExperience(practitionerId: string, experienceId: string, experience: JobExperience) {
    const url = environment.apiUrl + `/practitioners/${practitionerId}/jobExperiences/${experienceId}`;
    return this.http.put<Practitioner>(url, experience).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  deleteExperience(practitionerId: string, experienceId: string) {
    const url = environment.apiUrl + `/practitioners/${practitionerId}/jobExperiences/${experienceId}`;
    return this.http.delete<Practitioner>(url).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  toggleVisibility(practitionerId: string, isVisible: boolean) {
    const url = environment.apiUrl + `/practitioners/${practitionerId}/visibility`;
    return this.http.put<Practitioner>(url, { isVisible }).pipe(
      map((practitionerUpdated) => {
        this.setState(practitionerUpdated);
        return practitionerUpdated;
      })
    );
  }

  searchPractitioner(firstName: string, lastName: string) {
    const { href } = new URL(`/practitioners/search?firstName=${firstName}&lastName=${lastName}`, environment.apiUrl);
    return this.http.get<{ appinesPractitioners: ColleaguePractitioner[]; externalPractitioner: ColleagueUnsubscribedPractitioner[] }>(href);
  }
}
